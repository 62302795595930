












































import Base from '@/mixins/Base.vue';
import { IProject, IProjectResponse } from '@/interfaces/project';

const component = Base.extend({
    data() {
        return {
            active: false,

            confirmation: '',
            project: undefined as any | IProject,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.project.name;
        },
    },
    mounted() {
        this.active = true;
        this.getProject();
    },
    methods: {
        getProject(): void {
            this.get<IProjectResponse>(`projects/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.project = data.data;
                });
        },
        save(): void {
            if (this.validated) {
                this.delete(`projects/${this.project.id}`).then(() => {
                    this.$router.push({ name: 'projects.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
